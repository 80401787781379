import React, {useEffect,useState} from 'react';
import { CheckboxV2, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText"

function ForwardEmail(props) {

    ////If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_email_checkbox(props) === false){
            props.setForwardEmail(false)
            //inorder to hide the tr tag of additional recipient if not selected in app.js page
            props.onForwardEmailVisibilityChange(true);
        } 
        else{
            if(props.onForwardEmailVisibilityChange){
                props.onForwardEmailVisibilityChange(false);
            }
            
        }       
    }, [props.environmentSelected])

    
     //If selected environment is cannot use the checkbox, hide
     if(should_display_email_checkbox(props) === false){
        return null
    }
    
    return <>
       
      
            <td className=' td_row_leftvalue'>
                <CheckboxV2 className="verint-CheckboxV2-regular" id="Additional_recipient" style={{marginTop :"3px"}}
                    name="Additional_recipient" type = "checkbox"  value = {props.forwardEmail} disabled={props.disabled} checked={props.checked} 
                    onChange={(e) => {props.setForwardEmail(!props.forwardEmail);}}></CheckboxV2>  

                <Label id="Additional_recipient_label"className="verint-regular-label"   forhtml="Additional_recipient">Additional Recipient </Label>
                <OverlayText tooltipText={"Use this option to send the lab details to an additional user. An example of this could be to a partner."}></OverlayText>
            </td>  
     
    </>      
}

export default ForwardEmail;

function should_display_email_checkbox(props)
{
    var jsonData =props.environemntRecords

    for( var i =0; i < jsonData.Count; i++)
    {
        //console.log("grp name",jsonData.Items[i].group_name , "temp", jsonData.Items[i].template_id)
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].Additional_recipient === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}